import store from './store/index'
import constants from './constants'
export default {
    methods: {
        getPhotoPath(filePath = '', filename = '') {
            if (typeof filename == 'string' && filename && filename.startsWith('http')) {
                return filename
            } else if (typeof filename == 'string' && filename && filename != 'null') {
                let prefix = filePath.indexOf('codex/') != -1 ? 'codex' : '';
                let path = filePath.replace('codex/', '').replace('uploads/', '')
                return (process.env.VUE_APP_DO_URL + ('/' + prefix + '/' + path + '/' + filename).replaceAll('//', '/'))
            } else {
                return '../../assets/images/Avatar.png';
            }
        },
        getInitials(name = ' ') {
            const fullName = name.split(' ');
            const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
            return initials.toUpperCase();
        },
        parseDate(dateString) {
            let dateParsed = dateString.match(/\d+/g)
            dateParsed[3] = 3 in dateParsed ? dateParsed[3] : 0
            dateParsed[4] = 4 in dateParsed ? dateParsed[4] : 0
            dateParsed[5] = 5 in dateParsed ? dateParsed[5] : 0

            return new Date(
                Date.UTC(
                    dateParsed[0],
                    dateParsed[1] - 1,
                    dateParsed[2],
                    dateParsed[3],
                    dateParsed[4],
                    dateParsed[5]
                )
            )
        },
        formatDateTime(dateString, utcTimeZone = false, ampm = false, est = false) {
            if (dateString && dateString != 'null' && dateString != '0000-00-00 00:00:00' && dateString != '0000-00-00') {
                if (ampm) {
                    if (!utcTimeZone)
                        return this.parseDate(dateString)
                            .toLocaleString(
                                'en-US',
                                {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                })
                    else if (est == true)
                        return this.parseDate(dateString)
                            .toLocaleString(
                                'en-US',
                                {
                                    weekday: 'short',
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    hour12: true,
                                    timeZone: 'America/Toronto',
                                    timeZoneName: 'short' 
                                })
                    else
                        return this.parseDate(dateString)
                            .toLocaleString(
                                'en-US',
                                {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                    timeZone: 'UTC'
                                })
                } else {
                    if (!utcTimeZone)
                        return this.parseDate(dateString).toString().substr(0, 21)
                    else
                        return this.parseDate(dateString).toUTCString().replace(',', '').substr(0, 21)
                }

            }
            else
                return ''
        },
        formatDate(dateString, showSlash = 0, splitWith = '-') {
            if (!dateString)
                return null
            else if (dateString == '9999-12-31')
                return 'Never'

            if (dateString.length > 10) { // if string is datetime then convert it to locale timezone and then split
                let dateParsed = dateString.match(/\d+/g)
                dateParsed[3] = 3 in dateParsed ? dateParsed[3] : 0
                dateParsed[4] = 4 in dateParsed ? dateParsed[4] : 0
                dateParsed[5] = 5 in dateParsed ? dateParsed[5] : 0

                let utcDate = new Date(
                    Date.UTC(
                        dateParsed[0],
                        dateParsed[1] - 1,
                        dateParsed[2],
                        dateParsed[3],
                        dateParsed[4],
                        dateParsed[5]
                    )
                )
                dateString = utcDate.toLocaleString('en-GB', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).substring(0, 10)
            }

            const [year, month, day] = dateString.split(splitWith);
            if (showSlash)
                return `${month}/${day}/${year}`;
            else
                return `${month}-${day}-${year}`;
        },
        checkPermission(permissionCode, type = 1) {
            // check if user has access based on permission type
            // permission type is routePermissions or actionPermissions
            // if route dose not require permission check than pass it in skipPermissionRoutes
            const permissionType = constants.common.permissionType;
            const skipPermissionRoutes = constants.common.skipPermissions;
            if (
                permissionType[type] == 'routePermissions' &&
                skipPermissionRoutes.includes(permissionCode)
            ) {
                return true
            }

            const userPermissions = store.getters.authPermissions;
            if (userPermissions) {
                return userPermissions[permissionType[type]].includes(permissionCode);
            } else {
                return false;
            }
        },
        formatPhoneNumber(value) {
            if (value) {
                let x = value.replace(/\D/g, '').match(/^(\d{0,3})(\d{0,3})(\d{0,8})/);

                return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            }
            return ''
        },
        getPartStatusColor(status) {
            if (status == 1)
                return 'border-gray-200 text-gray-500'
            else if (status == 2)
                return 'border-yellow-100 text-yellow-400'
            else if (status == 3)
                return 'border-blue-200 text-blue-600'
            else if (status == 4)
                return 'border-green-100 text-green-600'
            else if (status == 5)
                return 'border-red-100 text-red-700'
            else if (status == 6)
                return 'border-gray-200 text-gray-900'
            else if (status == 7)
                return 'border-gray-200 text-gray-900'
            else if (status == 8)
                return 'border-gray-200 text-gray-900'
        },
        getBomStatusColor(status) {
            if (status == this.constants.bom.bom_status.inactive)
                return "border-neutral-200 text-neutral-800";
            else if (status == this.constants.bom.bom_status.active)
                return "border-neutral-200 text-green-600";
            else if (status == this.constants.bom.bom_status.draft)
                return "border-neutral-200 text-neutral-600";
        },
        getDepartmentId() {
            if (this.userData.department.toLowerCase() == 'marketing')
                return 2;
            else if (this.userData.department.toLowerCase() == 'operations')
                return 3;
            else if (this.userData.department.toLowerCase() == 'regulatory')
                return 4;
            else if (this.userData.department.toLowerCase() == 'warehouse')
                return 5;
            else if (this.userData.department.toLowerCase() == 'quality')
                return 6;
            else if (this.userData.department.toLowerCase() == 'customer service')
                return 7;
            else
                return 8;
        },
        randomBg() {
            let num = Math.floor(Math.random() * (7 - 1 + 1)) + 1;
            var cssBg = "bg-yellow-500";
            if (num == 1) cssBg = "bg-red-500";
            else if (num == 2) cssBg = "bg-green-500";
            else if (num == 3) cssBg = "bg-indigo-500";
            else if (num == 4) cssBg = "bg-orange-500";
            else if (num == 5) cssBg = "bg-rose-500";
            else if (num == 6) cssBg = "bg-indigo-500";
            else if (num == 7) cssBg = "bg-orange-500";
            return cssBg;
        },
        randomColor() {
            let num = Math.floor(Math.random() * (7 - 1 + 1)) + 1;
            var cssBg =
                "text-yellow-600 hover:text-yellow-800 bg-yellow-100 hover:bg-yellow-200";
            if (num == 1)
                cssBg =
                    "text-yellow-600 hover:text-yellow-800 bg-yellow-100 hover:bg-yellow-200";
            else if (num == 2)
                cssBg =
                    "text-green-600 hover:text-green-800 bg-green-100 hover:bg-green-200";
            else if (num == 3)
                cssBg =
                    "text-indigo-600 hover:text-indigo-800 bg-indigo-100 hover:bg-indigo-200";
            else if (num == 4)
                cssBg =
                    "text-orange-600 hover:text-orange-800 bg-orange-100 hover:bg-orange-200";
            else if (num == 5)
                cssBg =
                    "text-rose-600 hover:text-rose-800 bg-rose-100 hover:bg-rose-200";
            else if (num == 6)
                cssBg =
                    "text-indigo-600 hover:text-indigo-800 bg-indigo-100 hover:bg-indigo-200";
            else if (num == 7)
                cssBg =
                    "text-orange-600 hover:text-orange-800 bg-orange-100 hover:bg-orange-200";
            return cssBg;
        },
        locationBgColor(priority) {
            const colors = {
                1:'text-red-600 bg-red-200',
                2:'text-yellow-600 bg-yellow-200',
                3:'text-green-600 bg-green-200',
                4:'text-cyan-600 bg-cyan-200',
                5:'text-blue-600 bg-blue-200',
                6:'text-neutral-600 bg-neutral-200'
            };
            if (priority in colors){
                return colors[priority]
            }
            return colors[1];
        },
        padWithZero(num) {
            return num < 10 ? '0' + num : '' + num;
        },  
        roundDecimalQB(num, procession_digit = 2) {
            return +(Math.round(num + `e+${procession_digit}`) + `e-${procession_digit}`)
        },
        formatNumber(value, decimalPoints) {
            if (value) {
                const decimalPart = value.toString().split('.')[1];
                const decimalLength = decimalPart ? decimalPart.length : 0;
                if (decimalLength == 1) {
                    return value.toFixed(2)
                }
                const maxDecimalPlaces = decimalLength > decimalPoints ? decimalPoints : decimalLength;
                const decimalPlaces = maxDecimalPlaces > 0 ? maxDecimalPlaces : 2;
                let formattedNumber = Number(Math.round(value + 'e+' + decimalPlaces) + 'e-' + decimalPlaces).toFixed(decimalPlaces);
                // Remove trailing zeros
                formattedNumber = decimalPlaces > 2 ? formattedNumber.replace(/\.?0+$/, '') : formattedNumber;

                return formattedNumber;
            }
            return value
        },
        scrollToAccordionContent(id) {
            const mainContent = document.getElementById('mainContent');
            const accordionContent = this.$refs[`${id}_ref`];

            if (mainContent && accordionContent) {
                const targetScrollTop = accordionContent.getBoundingClientRect().top + mainContent.scrollTop - mainContent.offsetHeight * 0.2;
                this.smoothScroll(mainContent, targetScrollTop);
            }
        },
        smoothScroll(element, target, duration = 500) {
            const start = element.scrollTop;
            const change = target - start;
            const startTime = performance.now();
            const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

            const animateScroll = (currentTime) => {
                const progress = Math.min((currentTime - startTime) / duration, 1);
                element.scrollTop = start + change * easeInOutQuad(progress);
                if (progress < 1) requestAnimationFrame(animateScroll);
            };

            requestAnimationFrame(animateScroll);
        },
    },
    computed: {
        userAuth() {
            return store.getters.isAuthenticated
        },
        userData() {
            return store.getters.authUser
        },
        skidLabelStyle() {
            return `<style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
                
                * {
                    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
                    color-adjust: exact !important;                 /* Firefox 48 – 96 */
                    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
                }
                
                body, html {
                    margin: 0;
                    padding: 0;
                    font-family: 'Poppins', sans-serif;
                }

                .printLabelArea {
                    transform: rotate(-90deg);
                    webkit-transform: rotate(-90deg);
                    -moz-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    -o-transform: rotate(-90deg);
                    font-family: 'Poppins', sans-serif;
                    margin-top:5% !important;
                    margin:auto;
                    height:100vh;
                    padding: 25px 10px;
                    display: block;
                    page-break-after: always;
                }
                
                .rotate {
                    text-align:center
                }

                .spQrCode img{
                    width: 50px;
                }

                .qrcode {
                    display: flex;
                }

                @media print {
                    @page {
                        size:8.5in 11in landscape;
                        margin: 0%;
                        padding: 50px 25px;
                        font-family: 'Poppins', sans-serif;
                    }

                    .printLabelArea {
                        transform: rotate(0deg);
                        webkit-transform: rotate(0deg);
                        -moz-transform: rotate(0deg);
                        -ms-transform: rotate(0deg);
                        -o-transform: rotate(0deg);
                        width:90%;
                        height:90vh;
                        padding: 0;
                        font-family: 'Poppins', sans-serif;
                    }

                    table tr td, table tr th {
                        page-break-inside: avoid;
                    }
                   
                    thead {
                        display: table-header-group;
                    }
                }
            </style>`
        },
        boxLabelStyle() {
            return `<style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
                
                * {
                    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
                    color-adjust: exact !important;                 /* Firefox 48 – 96 */
                    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
                }

                body, html {
                    margin: 0;
                    padding: 0;
                    font-family: 'Poppins', sans-serif;
                }

                .printBoxArea {
                    width: 4in;
                    height: 2in;
                    display: block;
                    margin-bottom: 20px;
                    font-family: 'Poppins', sans-serif;
                }
                
                hr {
                    margin: 0
                }

                .rotate {
                    text-align:center
                }

                .qrcode {
                    display: flex;
                }

                @media print {
                    body {
                        margin: 0 0 0 0;
                        padding: 0 0 0 0;
                        font-family: 'Poppins', sans-serif;
                    }

                    @page {
                        size:4in 2in;
                        margin: 0;
                        padding: 0;
                        font-family: 'Poppins', sans-serif;
                    }
                
                    .printBoxArea {
                        font-family: 'Poppins', sans-serif;
                        margin: 0;
                        padding: 0;
                    }
                }
            </style>`
        }
    }
}
<template>
<div class="printContainer">
    <div class="printBoxArea" style="position: relative; break-after: page;">
        <div style="width: 95%; margin-left: auto;">
            <table style="width: 100%;">
                <tbody>
                    <tr>
                    <td style="width: 12px;">
                        <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: inline; margin-bottom: 5px; margin-right: 3%; width: 38px; height: 38px;">
                            <path d="M22.4582 5.98633L37.2266 14.2936V30.9081L22.4582 39.2154L7.6897 30.9081V14.2936L22.4582 5.98633Z" stroke="#444451" stroke-width="3.32291" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M22.458 22.6002L37.2265 14.293" stroke="#444451" stroke-width="3.32291" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M22.458 22.5996V39.2141" stroke="#444451" stroke-width="3.32291" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M22.4582 22.6002L7.6897 14.293" stroke="#444451" stroke-width="3.32291" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M29.8424 10.1387L15.074 18.4459" stroke="#444451" stroke-width="3.32291" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </td>
                    <td>
                        <div style="font-weight: 600; font-size: 16px; text-transform: uppercase; padding-right: 12px; line-height: 1;">HDGHGAHT-0001</div>
                    </td>
                    <td style="width: 41px;"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAAAXNSR0IArs4c6QAAAYVJREFUWEftV0EOwyAMK/9/NBPTMkVenARKOyqx40qpcRzHlFprPRb/lQ1yUoU2k5OIPJ7NZCklRYQ2B3zHe8Y2t8yGMvkokJ6NtoO053IgXKv/l7WMQbZHWx8yqUHIBwSM9WGrArKHfk+D9Q46HSSTyAbZqM6UG7WHuruFSU/srFnkgPLu5Y2zPMjI0bEzrdJbkpli5hE463lU0pE9uyzI8kYEpS0oMnkcme7QYKGXTQsN7O8gdWlGgwOyH41PdISvM2SuD1hGbSt644hlbyx6ek3lyWVB9pQmE+tYY3jpJ11u3ARLak0WTEtvG4EQndWra0FZ3S0BMmPATLNsblvrT4XeCGSUxnWS8sLyUHenBP25PqA0mASYBWmAQxexzB1nCZA9ndmbgjAhDTMZgbQaxJs+qL8oPU29LTJgIQjQ9s8hsinIErd83GqyKCFZbHYHjLPdjSBYgrfWdTOZ8Uk2u71gmw3I7ljMhAZt1NZhLgcZMXjn81SevBOQ9a0NclYFHsHkC4e0pKRy7INrAAAAAElFTkSuQmCC"></td>
                    <td><span class="rotate" style="font-size: 7px; color: rgb(68, 68, 81); font-weight: 400; text-transform: uppercase; height: 36px;">HDGHGAHT-0001</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style="color: rgb(229, 229, 229);">
        <div style="margin-top: 3px;">
            <table style="width: 95%; margin-left: auto;">
                <tbody>
                    <tr>
                    <td style="width: 20%; font-size: 12px; font-weight: 500; color: rgb(140, 140, 159); line-height: 1;">SKU</td>
                    <td style="width: 80%; font-size: 18px; font-weight: 500; color: rgb(68, 68, 81); line-height: 1;">192548</td>
                    </tr>
                    <tr>
                    <td style="width: 20%; font-size: 12px; font-weight: 500; color: rgb(140, 140, 159); line-height: 1;">Name</td>
                    <td style="width: 80%; font-size: 16px; font-weight: 400; text-transform: capitalize; color: rgb(68, 68, 81); line-height: 1;">HTP-IV 100</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="margin-top: 3px;">
            <table style="width: 95%; margin-left: auto;">
                <tbody>
                    <tr>
                    <td style="width: 50%; font-size: 12px; font-weight: 500;">
                        <table>
                            <tbody>
                                <tr>
                                <td style="width: 20%; font-size: 12px; font-weight: 500; color: rgb(140, 140, 159); line-height: 1; padding: 0px 6px 6px 0px;">Lot</td>
                                <td style="width: 80%; font-size: 16px; font-weight: 400; color: rgb(68, 68, 81); line-height: 1; padding: 0px 6px 6px 0px;">HDGHGAHT</td>
                                </tr>
                                <tr>
                                <td style="width: 20%; font-size: 12px; font-weight: 500; color: rgb(140, 140, 159); line-height: 1; padding: 0px 6px 6px 0px;">DOE</td>
                                <td style="width: 80%; font-size: 16px; font-weight: 400; color: rgb(68, 68, 81); line-height: 1; padding: 0px 6px 6px 0px;">10-31-2023</td>
                                </tr>
                                <tr>
                                <td style="width: 20%; font-size: 12px; font-weight: 500; color: rgb(140, 140, 159); line-height: 1; padding: 0px 6px 6px 0px;">Units/Box</td>
                                <td style="width: 80%; font-size: 16px; font-weight: 400; color: rgb(68, 68, 81); line-height: 1; padding: 0px 6px 6px 0px;">3</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td style="width: 50%; font-weight: 500;">
                        <table>
                            <tbody>
                                <tr>
                                <td style="width: 20%; font-size: 12px; font-weight: 500; color: rgb(140, 140, 159); line-height: 1; padding: 0px 6px 6px 0px;">PO</td>
                                <td style="width: 80%; font-size: 16px; font-weight: 400; color: rgb(68, 68, 81); line-height: 1; padding: 0px 6px 6px 0px;">1516</td>
                                </tr>
                                <tr>
                                <td style="width: 20%; font-size: 12px; font-weight: 500; color: rgb(140, 140, 159); line-height: 1; padding: 0px 6px 6px 0px;">Received</td>
                                <td style="width: 80%; font-size: 16px; font-weight: 400; color: rgb(68, 68, 81); line-height: 1; padding: 0px 6px 6px 0px;">10-17-2023</td>
                                </tr>
                                <tr>
                                <td style="width: 20%; font-size: 12px; font-weight: 500; color: rgb(140, 140, 159); line-height: 1; padding: 0px 6px 6px 0px;">Location</td>
                                <td style="width: 80%; font-size: 16px; font-weight: 400; color: rgb(68, 68, 81); line-height: 1; padding: 0px 6px 6px 0px;">MultiSku-17-10</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>
<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
    
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important;                 /* Firefox 48 – 96 */
        print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
    }

    body, html {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
    }

    .printBoxArea {
        width: 4in;
        height: 2in;
        display: block;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
    }
    
    hr {
        margin: 0
    }

    .rotate {
        text-align:center
    }

    .qrcode {
        display: flex;
    }

    @media print {
        body {
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            font-family: 'Poppins', sans-serif;
        }

        @page {
            size:4in 2in;
            margin: 0;
            padding: 0;
            font-family: 'Poppins', sans-serif;
        }
    
        .printBoxArea {
            font-family: 'Poppins', sans-serif;
            margin: 0;
            padding: 0;
        }
    }
</style>